//React
import React from "react"

//Packages
import Helmet from "react-helmet"

//Components
import Layout from "../components/layout"

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // getHtml(){
  //   const pages = new FroalaPages("___gatsby")
  //   await pages.getHTML();
  // }

  componentDidMount() {
    const pages = new FroalaPages("___gatsby", {
      key:
        "3GyD-13F-11hJD3bE3C6A3E4F2F3yQNDMIJd1IQNSEa2EUAf1XVFQa1EaD4D3B2B2A18A14B3C9A3A2==",
      showDownloadCounter: true,
      pageRightButtons: [["save"]],
      // pageCenterButtons: [
      //   '<div style="display: flex;"><button class="btn btn-primary">test</button></div>',
      // ],
      pageCenterButtons: [""],
      designsThumbsDir: "/design/screenshots/",
      designsImagesDir: "/design/images/",

      designStylesheets: [
        "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css",
        "https://cdn.jsdelivr.net/gh/froala/design-blocks@master/dist/css/froala_blocks.min.css",
        "https://cdn.jsdelivr.net/npm/froala-editor@latest/css/froala_editor.pkgd.min.css",
        "https://cdn.jsdelivr.net/npm/froala-editor@latest/css/froala_style.min.css",
        "/design/css/editor.css",
      ],
      designJavascripts: [
        "https://code.jquery.com/jquery-3.2.1.slim.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js",
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js",
        "https://cdn.jsdelivr.net/npm/froala-editor@latest/js/froala_editor.pkgd.min.js",
        "https://use.fontawesome.com/releases/v5.5.0/js/all.js",
        // "/design/templates/pico.js",
        // "/design/templates/picodefault.js",
        // "/design/js/editor.js",
      ],
    })
  }
  render() {
    return (
      <>
        <Helmet
          title="Picopulse"
          meta={[
            {
              name: "description",
              content:
                "Picohealth helps reduce risk and drive customer confidence with mobile employee health screenings for employees and customers",
            },
            {
              name: "keywords",
              content:
                "Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety",
            },
          ]}
        >
          {/* <link
            href="node_modules/froala-pages/css/froala_pages.min.css"
            rel="stylesheet"
            type="text/css"
          />
          <script
            type="text/javascript"
            src="node_modules/froala-pages/js/froala_pages.min.js"
          ></script>
          <script
            type="text/javascript"
            src="node_modules/froala-pages/js/pages_design_blocks_pkgd.min.js"
          ></script> */}
        </Helmet>

        {/* <div id="selector-id"></div> */}
      </>
    )
  }
}

export default Login
